
import {
    defineComponent, ref, reactive, PropType
} from 'vue';
import { peopleDeviceApi } from '@/api';

export interface SetDepartment {
    DepartmentName: string;
    Floor: string[];
    ID: string;
}
export default defineComponent({
    props: {
        initForm: {
            type: Object as PropType<SetDepartment>,
            default: () => ({
                DepartmentName: '',
                Floor: [],
                ID: ''
            })
        },
        operate: {
            type: String as PropType<OperateType>,
            required: true
        }
    },
    emits: ['close', 'success'],
    setup(props, { emit }) {
        const formData = reactive({ ...props.initForm });
        const formRef = ref();

        // 楼层数据
        const floorOptions = ref<{key: string;value: string}[]>([{
            key: 'all',
            value: WordList.AllFloors
        }]);
        for (let i = 1; i < 129; i += 1) {
            floorOptions.value.push({
                key: i.toString(),
                value: i.toString()
            });
        }

        // 最多只能选10个floor层
        function changeFloor() {
            if (formData.Floor.length > 10) {
                formData.Floor.pop();
            }
        }

        // 表单规则
        const rules = {
            DepartmentName: [
                { required: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.ProperAllTextDepartmentName), trigger: 'blur' }
            ]
        };

        const submitApi = {
            add: peopleDeviceApi.addDepartment,
            edit: peopleDeviceApi.editDepartment
        };
        function submit() {
            formRef.value.validate((valid: boolean) => {
                if (valid) {
                    submitApi[props.operate]({
                        Name: formData.DepartmentName,
                        ID: formData.ID,
                        Floor: formData.Floor.includes('all') ? 'all' : formData.Floor.join(';')
                    }, () => {
                        emit('close');
                        emit('success');
                    });
                }
            });
        }
        return {
            formRef,
            formData,
            floorOptions,
            changeFloor,
            rules,
            submit
        };
    }
});
