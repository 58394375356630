import { Ref, ref } from 'vue';
import { community } from '@/data';

interface ComBuildType {
    UnitName: string;
    Editable: boolean;
    ID: string;
    Floor?: string;
}

interface EditBuildParams {
    Name: string;
    ID?: string;
}

// 添加楼栋是否可编辑标志位
const editableControl = (initData: Array<ComBuildType>) => {
    const comData: Array<ComBuildType> = [];
    initData.forEach((item) => {
        const tempData = item;
        tempData.Editable = false;
        comData.push(tempData);
    });
    return comData;
};

// 获取社区楼栋信息
function getBuilds() {
    const comBuilds: Ref<Array<ComBuildType>> = ref([]);
    community.getCommunityBuild((res: {
        data: Array<ComBuildType>;
    }) => {
        comBuilds.value = editableControl(res.data);
    });
    return comBuilds;
}

const initBuildData = {
    UnitName: '',
    Editable: true,
    ID: ''
};

export default null;
export {
    getBuilds,
    editableControl,
    ComBuildType,
    initBuildData,
    EditBuildParams
};