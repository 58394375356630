
import {
    defineComponent,
    ref, Ref, computed, nextTick,
    reactive
} from 'vue';
import { useRoute } from 'vue-router';
import noticeBase from '@/util/notice.base';
import HttpRequest from '@/util/axios.config';
import { community } from '@/data';
import router, { communitySubRouter, communityBaseRouter } from '@/router';
import { isOffice } from '@/methods/role';
import { ChangeWordList } from '@/methods/setText';
import { peopleDeviceApi, residentDeviceApi } from '@/api';
import type { ElScrollbar } from 'element-plus';
import SetDepartmentDialog, { SetDepartment } from './components/set-department-dialog.vue';
import editMenu from './editMenu.vue';
import {
    getBuilds,
    ComBuildType,
    editableControl,
    initBuildData,
    EditBuildParams
} from './util';

export default defineComponent({
    components: {
        editMenu,
        SetDepartmentDialog
    },
    props: {
        build: {
            type: String,
            default: 'community'
        },
        DepartmentID: {
            type: String,
            default: 'all'
        }
    },
    setup(props, { emit }) {
        const isAddBuild = ref(false);
        const comBuilds = getBuilds();
        // const activeID = computed(() => (useRoute().query ? useRoute().query.Build : ''));
        const activeID = computed(() => {
            if (isOffice()) {
                return useRoute().query ? useRoute().query.DepartmentID : '';
            }
            return useRoute().query ? useRoute().query.Build : '';
        });
        const showMenu = ref(false);
        const menuData: Ref<ComBuildType> = ref(initBuildData);
        const menuPosition = ref({
            top: 0,
            left: 0
        });
        const tempUnitName = ref('');
        const scrollbarRef = ref<InstanceType<typeof ElScrollbar>>();

        // 切换楼栋
        const changeComBuild = (id: string) => {
            if (!isAddBuild.value && activeID.value !== id) {
                if (isOffice()) {
                    router.push(`/${communityBaseRouter}/${communitySubRouter.residentDevice}?DepartmentID=${
                        id}&type=${id === 'public' ? 'device' : 'user'}`);
                } else {
                    router.push(`/${communityBaseRouter}/${communitySubRouter.residentDevice}?Build=${
                        id}&type=${id === 'public' ? 'device' : 'user'}`);
                }
                emit('changeBuild', id);
            }
        };

        // 编辑楼栋
        const editBuild = (event: PointerEvent, item: ComBuildType) => {
            event.preventDefault();
            event.stopPropagation();
            // 处于增加楼栋操作下，不进行编辑操作
            if (isAddBuild.value) return;

            showMenu.value = true;
            menuPosition.value.top = event.clientY + document.body.scrollTop;
            menuPosition.value.left = event.clientX + document.body.scrollLeft;
            menuData.value = item;
        };

        // 点击其他地方隐藏右键菜单
        document.body.addEventListener('click', () => {
            showMenu.value = false;
        }, false);

        // 6.5.3 office办公梯控需求
        const isShowSetDepartmentDialog = ref(false);
        const setDepartmentForm = reactive<SetDepartment>({
            DepartmentName: '',
            ID: '',
            Floor: []
        });
        const setDepartmentOperate = ref<OperateType>('add');

        const addBuild = () => {
            if (isAddBuild.value) return;
            if (isOffice()) {
                setDepartmentOperate.value = 'add';
                setDepartmentForm.ID = '';
                setDepartmentForm.Floor = [];
                setDepartmentForm.DepartmentName = '';
                isShowSetDepartmentDialog.value = true;
            } else {
                isAddBuild.value = true;
                const newBuild = initBuildData;
                comBuilds.value.push(newBuild);
                nextTick(() => {
                    scrollbarRef.value!.wrap.scrollTop = scrollbarRef.value!.wrap.scrollHeight;
                });
            }
        };

        const cancelEdit = (event: PointerEvent, item: ComBuildType) => {
            event.stopPropagation();
            // 新增楼栋取消
            if (!item.ID) {
                comBuilds.value.pop();
            } else {
                // 重命名取消
                menuData.value.Editable = false;
            }
            tempUnitName.value = '';
            isAddBuild.value = false;
        };

        const refreshBuilds = () => {
            community.getCommunityBuild((res: {
                data: Array<ComBuildType>;
            }) => {
                comBuilds.value = editableControl(res.data);
                menuData.value.ID = '';
            });
        };

        const confirmEdit = (item: ComBuildType) => {
            if (!tempUnitName.value) {
                noticeBase.messageBox('alert', WordList.RuleNameEmpty, '', 'error')(() => false);
                return;
            }
            let api = isOffice() ? peopleDeviceApi.addDepartment : residentDeviceApi.addBuild;
            if (item.ID) {
                api = isOffice() ? peopleDeviceApi.editDepartment : residentDeviceApi.editBuild;
            }
            const buildParams: EditBuildParams = {
                Name: tempUnitName.value
            };
            if (item.ID) {
                buildParams.ID = item.ID;
            }
            api(buildParams, () => {
                refreshBuilds();
            });
            isAddBuild.value = false;
        };

        // 重命名楼栋
        const renameBuild = () => {
            if (isOffice()) {
                setDepartmentOperate.value = 'edit';
                setDepartmentForm.ID = menuData.value.ID;
                setDepartmentForm.DepartmentName = menuData.value.UnitName;
                if (menuData.value.Floor) {
                    setDepartmentForm.Floor = menuData.value.Floor === 'all' ? ['all'] : menuData.value.Floor.split(';');
                } else {
                    setDepartmentForm.Floor = [];
                }
                isShowSetDepartmentDialog.value = true;
            } else {
                menuData.value.Editable = true;
                tempUnitName.value = menuData.value.UnitName;
                isAddBuild.value = true;
            }
        };
        // 删除楼栋
        const deleteBuild = () => {
            noticeBase.messageBox('confirm', `${WordList.TabelDeleteText2} ${menuData.value.UnitName}?`, WordList.TabelDeleteTitle, 'warning')(() => {
                if (isOffice()) {
                    peopleDeviceApi.delDepartment({
                        ID: menuData.value.ID
                    }, () => {
                        refreshBuilds();
                    });
                } else {
                    residentDeviceApi.delBuild({
                        ID: menuData.value.ID
                    }, () => {
                        refreshBuilds();
                    });
                }
            });
        };
        return {
            activeID,
            comBuilds,
            changeComBuild,
            isAddBuild,
            addBuild,
            cancelEdit,
            confirmEdit,
            editBuild,
            showMenu,
            menuPosition,
            renameBuild,
            deleteBuild,
            tempUnitName,
            ChangeWordList,
            isOffice: isOffice(),
            scrollbarRef,
            isShowSetDepartmentDialog,
            setDepartmentForm,
            setDepartmentOperate,
            refreshBuilds
        };
    }
});
